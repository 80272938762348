<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>{{ $t("vinSearch.title") }}</span>
                <small>VIN: {{ vin }}</small>
              </label>
              <v-spacer></v-spacer>
              <v-btn
                medium
                light
                min-height="48"
                class="green darken-3 white--text"
                elevation="0"
                @click="expandToggle"
              >
                <v-icon>mdi-email</v-icon>
              </v-btn>
              <a :href="pdfUrl" target="_blank" class="text-decoration-none">
                <v-btn
                  medium
                  light
                  min-height="48"
                  class="green darken-3 white--text ml-4"
                  elevation="0"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </a>
            </v-card-title>

            <div class="pa-4" v-if="apiErrorMessage">
              <ApiErrorMessage :message="apiErrorMessage" />
            </div>
            <v-card
              v-if="isExpanded"
              class="pa-4 form-custom d-flex align-center"
              flat
            >
              <v-text-field
                hide-details="auto"
                v-model="email"
                :error-messages="emailError"
                @blur="$v.email.$touch()"
                class="mr-3"
                height="50"
                elevation="0"
                :loading="loading"
                dense
                :label="$t('vinSearch.enterEmail')"
                outlined
              ></v-text-field>
              <v-btn
                :disabled="$v.$invalid && $v.$dirty"
                :loading="loading"
                height="50"
                medium
                @click="sendPdf"
                dense
                class="green darken-4 green-gradient white--text"
                elevation="0"
              >
                {{ $t("vinSearch.send") }}
              </v-btn>
            </v-card>

            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <!-- Basic Data -->
              <v-card-title class="card-header green lighten-4">
                <label class="sub-header-table d-flex flex-column">
                  <span>{{ $t("vinSearch.basicData") }}</span>
                </label>
              </v-card-title>
              <div class="d-flex">
                <v-col primary cols="12" sm="6">
                  <div
                    v-for="(value, key, index) in vinSearchReport.basic_data"
                    class="d-flex flex-column custom-cards"
                    :key="'basic_data1' + index"
                  >
                    <template
                      v-if="index <= divideData(vinSearchReport.basic_data)"
                    >
                      <div class="">
                        <b>{{ key }}:</b> {{ value }}
                      </div>
                    </template>
                  </div>
                </v-col>
                <v-col primary cols="12" sm="6">
                  <div
                    v-for="(value, key, index) in vinSearchReport.basic_data"
                    class="d-flex flex-column custom-cards"
                    :key="'basic_data2' + index"
                  >
                    <template
                      v-if="index > divideData(vinSearchReport.basic_data)"
                    >
                      <div class="">
                        <b>{{ key }}:</b> {{ value }}
                      </div>
                    </template>
                  </div>
                </v-col>
              </div>

              <!-- Engines -->
              <v-card-title class="card-header green lighten-4">
                <label class="sub-header-table d-flex flex-column">
                  <span>{{ $t("vinSearch.engines") }}</span>
                </label>
              </v-card-title>
              <div
                v-for="(engine, index) in vinSearchReport.engines"
                :key="'enginesArray' + index"
              >
                <div class="d-flex">
                  <v-col primary cols="12" sm="6">
                    <div
                      v-for="(value, key, index) in engine"
                      class="d-flex flex-column custom-cards"
                      :key="'engine1' + index"
                    >
                      <template v-if="index <= divideData(engine)">
                        <div class="">
                          <b>{{ key }}:</b> {{ value }}
                        </div>
                      </template>
                    </div>
                  </v-col>
                  <v-col primary cols="12" sm="6">
                    <div
                      v-for="(value, key, index) in engine"
                      class="d-flex flex-column custom-cards"
                      :key="'engine1' + index"
                    >
                      <template v-if="index > divideData(engine)">
                        <div class="">
                          <b>{{ key }}:</b> {{ value }}
                        </div>
                      </template>
                    </div>
                  </v-col>
                </div>
                <hr />
              </div>

              <!-- Transmissions -->
              <v-card-title class="card-header green lighten-4 mt-5">
                <label class="sub-header-table d-flex flex-column">
                  <span>{{ $t("vinSearch.transmissions") }}</span>
                </label>
              </v-card-title>
              <div
                v-for="(transmission, index) in vinSearchReport.transmissions"
                :key="'transmissionArray' + index"
              >
                <div class="d-flex">
                  <v-col primary cols="12" sm="6">
                    <div
                      v-for="(value, key, index) in transmission"
                      class="d-flex flex-column custom-cards"
                      :key="'transmission1' + index"
                    >
                      <template v-if="index <= divideData(transmission)">
                        <div class="">
                          <b>{{ key }}:</b> {{ value }}
                        </div>
                      </template>
                    </div>
                  </v-col>
                  <v-col primary cols="12" sm="6">
                    <div
                      v-for="(value, key, index) in transmission"
                      class="d-flex flex-column custom-cards"
                      :key="'transmission2' + index"
                    >
                      <template v-if="index > divideData(transmission)">
                        <div class="">
                          <b>{{ key }}:</b> {{ value }}
                        </div>
                      </template>
                    </div>
                  </v-col>
                </div>
                <hr />
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },

  data() {
    return {
      loading: false,
      email: "",
      isExpanded: false,
      apiErrorMessage: null,
      errors: {
        email: null,
        vin: null,
      },
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters({
      vinSearchReport: "vinSearchReport/getVinSearchReport",
      vin: "vinSearchReport/getVin",
      pdfPath: "vinSearchReport/getPdfPath",
      pdfUrl: "vinSearchReport/getPdfUrl",
    }),
    emailError() {
      let error = null;
      if (this.$v.email.$dirty && !this.$v.email.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("vinSearch.email"),
        });
      }
      if (this.$v.email.$dirty && !this.$v.email.email) {
        error = this.$t("vinSearch.emailIsInvalid", {
          field: this.$t("vinSearch.email"),
        });
      }
      if (this.errors && this.errors.email) {
        error = this.errors.email;
      }
      return error;
    },
    vinError() {
      let error = null;
      if (this.$v.vin.$dirty && !this.$v.vin.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("vinSearch.vin"),
        });
      }
      if (this.errors && this.errors.vin) {
        error = this.errors.vin;
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendPdfToMail: "vinSearchReport/sendPdfToMailAction",
    }),
    divideData(data) {
      if (data) {
        const length = Object.keys(data).length;
        return (length / 2).toFixed();
      }
    },
    expandToggle() {
      this.$v.$reset();
      if (this.isExpanded) {
        this.isExpanded = false;
        this.email = null;
        this.apiErrorMessage = null;
      } else {
        this.isExpanded = true;
      }
    },
    async sendPdf() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          await this.sendPdfToMail({
            email: this.email,
            vin: this.vin,
          });
          this.expandToggle();
        } catch ({ message }) {
          //this.apiErrorMessage = message;
          this.errors.email = message;
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
